import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';

import { pipe } from 'fp-ts/function';
import { filterEmptyStringToOption } from '@shared/utils/string';

const buildEnv = import.meta.env;

export function getConfig(): ImportMetaEnv {
  return {
    ...buildEnv,
    ...window._env_,
  };
}

export const getConfigValue = (key: string): O.Option<string> =>
  pipe(
    O.fromNullable(getConfig()[key]),
    O.filterMap(val => (typeof val === 'string' ? filterEmptyStringToOption(val) : O.none)),
  );

export enum ApplicationSpace {
  Admin = 'admin',
  Pro = 'pro',
}

const applicationSpaceConfigMatch: Record<ApplicationSpace, string> = {
  [ApplicationSpace.Admin]: 'VITE_ADMIN_PREFIX',
  [ApplicationSpace.Pro]: 'VITE_PRO_PREFIX',
};

export function getApplicationSpace(): O.Option<ApplicationSpace> {
  const hostPrefix = window.location.hostname.split('.')[0];

  const getFallBackSpace = () =>
    pipe(
      getConfigValue('VITE_FALLBACK_SPACE'),
      O.filterMap(s => {
        const space = s as ApplicationSpace;

        return Object.values(ApplicationSpace).includes(space) ? O.some(space) : O.none;
      }),
    );

  return pipe(
    applicationSpaceConfigMatch,
    R.toArray,
    A.findFirstMap(([space, configKey]) =>
      pipe(
        getConfigValue(configKey),
        O.filter(prefix => prefix === hostPrefix),
        O.map(() => space),
      ),
    ),
    O.alt(getFallBackSpace),
  );
}
