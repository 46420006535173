import styled from 'styled-components';

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  overflow-y: auto;

  > div {
    min-height: 0;
    padding: 30px 20px;

    > img {
      max-width: 100%;
    }

    > h1 {
      padding-top: 20px;
    }

    > p {
      padding: 15px 0;
    }
  }
`;
