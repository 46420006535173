import React, { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import ReactModal from 'react-modal';

import * as Styled from './Modal.styles';
import { Title } from '@styles/shared';
import { ModalActions } from './Modal.styles';
import { renderNullable } from '@shared/utils/render';

export interface ModalProps extends ReactModal.Props {
  title: ReactNode;
  width?: number;
  onRequestClose?: () => void;
  img?: JSX.IntrinsicElements['img'];
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({ children, title, width, style, onRequestClose, img, ...props }) => {
  const modalStyle: ReactModal.Styles = {
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '15px',
      zIndex: 10000,
      ...style?.overlay,
    },
    content: {
      position: 'relative',
      inset: 0,
      border: 'none',
      borderRadius: '5px',
      padding: '35px 30px',
      width: width ?? 500,
      maxWidth: '100%',
      maxHeight: '100%',
      boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.1)',
      outline: 'none',
      ...style?.content,
    },
  };

  const stopPropagation: MouseEventHandler = e => e.stopPropagation();

  return (
    <ReactModal onRequestClose={onRequestClose} style={modalStyle} {...props}>
      <Styled.ModalContainer onClick={stopPropagation}>
        <Styled.ModalTitle>
          <Styled.ModalCloseButton onClick={onRequestClose} />
          <Styled.ModalTitleContent>
            {renderNullable(img, props => (
              <img alt="Icône" style={{ marginRight: 13 }} {...props} />
            ))}

            <Title as={typeof title !== 'string' ? 'div' : 'p'} level="h2">
              {title}
            </Title>
          </Styled.ModalTitleContent>
        </Styled.ModalTitle>

        <Styled.ModalContent>{children}</Styled.ModalContent>
      </Styled.ModalContainer>
    </ReactModal>
  );
};

export default Modal;

export { ModalActions };
