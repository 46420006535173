import { httpService, HttpTask } from '@core/http';
import { AuthenticateParams, ImpersonationToken } from '../../auth/model';
import { OAuthProvider, ProAuthRole, ProProfile } from './model';
import { pipe } from 'fp-ts/function';

import * as TE from 'fp-ts/TaskEither';
import { sequenceT } from 'fp-ts/Apply';

export function getProfile(): HttpTask<ProProfile> {
  return pipe(
    sequenceT(TE.ApplyPar)(
      httpService.get<ProProfile>('/pro/profile'),
      httpService.get<Array<ProAuthRole>>('/pro/profile/roles'),
    ),
    TE.map(([profile, roles]) => ({
      ...profile,
      roles,
    })),
  );
}

export function logout(): HttpTask<void> {
  return httpService.post('/pro/logout');
}

export function authenticate(params: AuthenticateParams): HttpTask<ProProfile> {
  return pipe(httpService.post('/pro/authenticate', params), TE.chain(getProfile));
}

export function authenticateWithToken(token: ImpersonationToken): HttpTask<ProProfile> {
  return pipe(httpService.post('/pro/authenticate', undefined, { params: { token } }), TE.chain(getProfile));
}

export function authenticateSocial(provider: OAuthProvider, token: string, city?: string): HttpTask<ProProfile> {
  return pipe(httpService.post(`/pro/authenticate/${provider}`, { token }, { params: { city } }), TE.chain(getProfile));
}

export function authenticateAppleSocial(
  token: string,
  firstName?: string,
  lastName?: string,
  city?: string,
): HttpTask<ProProfile> {
  return pipe(
    httpService.post('/pro/authenticate/apple-web', { token, firstName, lastName }, { params: { city } }),
    TE.chain(getProfile),
  );
}

export function switchRole(role: ProAuthRole): HttpTask<ProProfile> {
  return pipe(httpService.post('/pro/switch', role), TE.chain(getProfile));
}

export function autoLogin(type: 'community' | 'shop', token: string): HttpTask<ProProfile> {
  return pipe(httpService.post(`/pro/${type}/auto-login/${token}`), TE.chain(getProfile));
}
