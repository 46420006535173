import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FontSizeKey,
  FontStyles,
  FontWeightKey,
  ThemeProps,
} from 'styled-components';

import * as R from 'fp-ts/Record';
import * as String from 'fp-ts/string';
import { pipe } from 'fp-ts/function';
import theme from '../theme';

export function fontFamily({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.font.family;
}

export const fontWeight = pipe(
  theme.font.weights,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<FontWeightKey, (props: ThemeProps<DefaultTheme>) => number>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.font.weights[key],
    }),
  ),
);

export const fontSize = pipe(
  theme.font.sizes,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<Extract<FontSizeKey, string>, (props: ThemeProps<DefaultTheme>) => string>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.font.sizes[key],
    }),
  ),
);

export const fontStyle = pipe(
  theme.font.style,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<keyof FontStyles, FlattenInterpolation<ThemeProps<DefaultTheme>>>>,
    (key, acc) => ({
      ...acc,
      [key]: css`
        ${props => {
          const styles = props.theme.font.style[key];

          return css`
            font-size: ${styles.size};
            font-weight: ${styles.weights};
            line-height: ${styles.lineHeight};
            font-style: ${styles.fontStyle};
            color: ${styles.color};
          `;
        }}
      `,
    }),
  ),
);
