import React, { FC, KeyboardEventHandler, useState } from 'react';
import styled, { css } from 'styled-components';

import showPasswordIcon from '@assets/icons/password/show-password.svg';
import {
  fontSize,
  fontWeight,
  mediaMax,
  primaryColor,
  rgba,
  secondaryColor,
  statusColor,
  tertiaryColor,
} from '../../utils';
import { Paragraph } from '../Paragraph';
import { useFormElementEvent } from './shared';
import removeIcon from '@assets/icons/shared/cross-invert.svg';

interface InputProps {
  error?: boolean;
}

export const Input = styled.input.attrs(props => ({
  type: props.type ?? 'text',
}))<InputProps>`
  display: block;
  height: 40px;
  width: 100%;
  padding: 0 15px;
  margin: 0;
  border: 1px solid ${tertiaryColor(400)};
  border-radius: 10px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${fontSize.medium};
  color: ${tertiaryColor(900)};
  line-height: 1.71;
  outline: none;
  transition: all 0.1s linear;
  background: rgba(255, 255, 255, 0.5);

  &::placeholder {
    font-family: inherit;
    font-size: ${fontSize.medium};
    font-style: italic;
    color: ${tertiaryColor(600)};
  }

  &:focus {
    outline: none;
    border-color: ${primaryColor(400)};
  }

  ${props =>
    props.disabled &&
    css`
      background: ${tertiaryColor(200)};
      border: 1px solid ${tertiaryColor(400)};
      color: ${rgba(tertiaryColor(900), 0.6)};
      cursor: not-allowed;
    `};

  ${props =>
    props.error &&
    css`
      border-color: ${statusColor('error')};
      color: ${statusColor('error')};

      &::placeholder {
        color: ${statusColor('error')};
        opacity: 0.5;
      }

      &:focus {
        border-color: ${statusColor('error')};
      }
    `};
`;

const DecreaseCharactersNumberContainer = styled.div`
  width: 100%;
  margin-top: 4px;
  padding-left: 5px;
  text-align: left;
  font-style: italic;
  font-size: ${fontSize.extraSmall};
  color: ${tertiaryColor(400)};
`;

const DecreaseCharactersNumber = styled.span`
  display: inline-block;
  width: 15px;
`;

const LengthRestrictedInputContainer = styled.div`
  @media screen and (${mediaMax.large}) {
    margin-bottom: 10px;
  }
`;
interface LengthRestrictedInputProps {
  maxLength: number;
  value?: string | null;
}

export const LengthRestrictedInput: FC<
  LengthRestrictedInputProps & React.InputHTMLAttributes<HTMLInputElement> & InputProps
> = ({ maxLength, value, ...props }) => {
  return (
    <LengthRestrictedInputContainer>
      <Input maxLength={maxLength} value={value} {...props} />
      <DecreaseCharactersNumberContainer>
        Caractères restants :{' '}
        <DecreaseCharactersNumber>
          {value ? (maxLength > value.length ? maxLength - value.length : 0) : maxLength}
        </DecreaseCharactersNumber>
      </DecreaseCharactersNumberContainer>
    </LengthRestrictedInputContainer>
  );
};

export const LengthRestrictedTextArea: FC<
  LengthRestrictedInputProps & React.TextareaHTMLAttributes<HTMLTextAreaElement> & InputProps
> = ({ maxLength, value, ...props }) => {
  return (
    <LengthRestrictedInputContainer>
      <TextArea maxLength={maxLength} value={value} {...props} />
      <DecreaseCharactersNumberContainer>
        Caractères restants :{' '}
        <DecreaseCharactersNumber>
          {value ? (maxLength > value.length ? maxLength - value.length : 0) : maxLength}
        </DecreaseCharactersNumber>
      </DecreaseCharactersNumberContainer>
    </LengthRestrictedInputContainer>
  );
};

const PasswordInputContainer = styled.div`
  position: relative;

  > button {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    background: url(${showPasswordIcon}) no-repeat center center;
    border: none;
    outline: none;
    cursor: pointer;

    &.show {
      &:after {
        display: block;
        position: absolute;
        bottom: 3px;
        left: 3px;
        content: '';
        height: 2px;
        width: 25px;
        background: ${tertiaryColor(400)};
        transform: rotate(-45deg);
        transform-origin: left;
      }
    }
  }
`;

export const PasswordInput: FC<React.InputHTMLAttributes<HTMLInputElement> & InputProps> = ({
  type,
  className,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => setShowPassword(old => !old);

  return (
    <PasswordInputContainer className={className}>
      <Input type={showPassword ? 'text' : 'password'} {...props} />
      <button type="button" className={showPassword ? 'show' : ''} tabIndex={-1} onClick={toggleShowPassword} />
    </PasswordInputContainer>
  );
};

export const TextArea = styled.textarea<InputProps>`
  display: block;
  height: 40px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 85px;
  padding: 10px 15px;
  margin: 0;
  border: 1px solid ${tertiaryColor(300)};
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  font-family: inherit;
  font-size: ${fontSize.medium};
  transition: all 0.1s linear;

  &::placeholder {
    font-family: inherit;
    font-size: ${fontSize.medium};
    font-style: italic;
    color: ${tertiaryColor(400)};
  }

  &:focus {
    outline: none;
    border-color: ${primaryColor(400)};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${statusColor('error')};
      color: ${statusColor('error')};

      &::placeholder {
        color: ${statusColor('error')};
        opacity: 0.5;
      }

      &:focus {
        border-color: ${statusColor('error')};
      }
    `};
`;

const LargeInputContainer = styled.div<{
  active: boolean;
  focused: boolean;
  error: boolean;
  handleDelete?: () => void;
}>`
  position: relative;
  overflow: hidden;

  > label {
    position: absolute;
    left: 15px;
    top: 13px;
    right: 14px;
    height: 24px;
    font-size: ${fontSize.medium};
    font-style: ${props => (props.active ? 'unset' : 'italic')};
    color: ${props => (props.error ? statusColor('error') : tertiaryColor(400))};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform-origin: left top;
    transform: ${props => (props.active ? 'translate3d(0, -8px, 0) scale(0.8)' : 'none')};
    transition: all 0.25s linear;
    z-index: 2;
  }

  > input {
    position: relative;
    height: 50px;
    padding: 12px 30px 0 15px;
    z-index: 1;
    background: none;

    ${({ handleDelete }) => handleDelete && 'padding-right: 32px;'}
  }
`;

const RemoveIcon = styled.i`
  content: url(${removeIcon});
  position: absolute;
  z-index: 10;
  right: 12px;
  top: 19px;
  width: 13px;
  height: 13px;
  cursor: pointer;
`;

const LargeInputError = styled.p`
  margin-top: 5px;
  font-size: ${fontSize.small};
  color: ${secondaryColor(400)};
`;

export type LargeInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> & {
  label: string;
  error?: string | boolean;
  handleDelete?: () => void;
};

export const LargeInput: FC<LargeInputProps> = ({
  label,
  className,
  id,
  value,
  error,
  onFocus,
  onBlur,
  handleDelete,
  ...inputProps
}) => {
  const { active, focused, handleBlur, handleFocus } = useFormElementEvent(value, onFocus, onBlur);

  return (
    <LargeInputContainer
      active={active}
      focused={focused}
      error={!!error}
      className={className}
      handleDelete={handleDelete}
    >
      <label htmlFor={id}>{label}</label>
      <Input id={id} value={value} onFocus={handleFocus} onBlur={handleBlur} error={!!error} {...inputProps} />
      {handleDelete && value && <RemoveIcon onClick={handleDelete} />}
      {typeof error === 'string' && <LargeInputError>{error}</LargeInputError>}
    </LargeInputContainer>
  );
};

export const BigInput = styled(Input)`
  height: 80px;
  font-size: ${fontSize.h2};
  font-weight: ${fontWeight.semiBold};

  &::placeholder {
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.semiBold};
  }
`;

export const LengthRestrictedBigInput = styled(LengthRestrictedInput)`
  height: 80px;
  font-size: ${fontSize.h2};
  font-weight: ${fontWeight.semiBold};

  &::placeholder {
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.semiBold};
  }
`;

const MoneyInputContainer = styled.div<{ active: boolean; focused: boolean; error: boolean; disabled: boolean }>`
  height: 50px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${props => (props.error ? statusColor('error') : tertiaryColor(400))};
  border-radius: 10px;

  > p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25px;
    flex: 0 0 25px;
    margin: 0 5px;
    line-height: 1.2;

    > span {
      position: relative;
      font-size: 8px;
    }
  }

  > label {
    width: 88px;
    position: absolute;
    left: 15px;
    top: 13px;
    right: 14px;
    height: 24px;
    font-size: ${fontSize.extraSmall};
    font-style: ${props => (props.active ? 'unset' : 'italic')};
    color: ${props => (props.error ? statusColor('error') : tertiaryColor(400))};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform-origin: left top;
    transform: ${props => (props.active ? 'translate3d(0, -8px, 0) scale(0.8)' : 'none')};
    transition: all 0.25s linear;
    z-index: 0;
    border-right: green;
  }

  > input {
    display: block;
    height: 50px;
    width: 70%;
    padding: 12px 15px 0;
    margin: 0;
    border: none;
    border-right: 1px solid ${tertiaryColor(200)};
    box-sizing: border-box;
    font-family: inherit;
    font-size: ${fontSize.medium};
    color: ${tertiaryColor(900)};
    line-height: 1.71;
    outline: none;
    transition: all 0.1s linear;
    background: rgba(255, 255, 255, 0.5);

    &[type='number'] {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    &::placeholder {
      font-family: inherit;
      font-size: ${fontSize.medium};
      font-style: italic;
      color: ${tertiaryColor(400)};
    }

    &:focus {
      outline: none;
      border-color: ${primaryColor(400)};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}
`;

const MoneyInputError = styled.p`
  margin-top: 5px;
  font-size: ${fontSize.small};
  color: ${secondaryColor(400)};
`;

export type MoneyInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> & {
  label: string;
  error?: string | boolean;
};

export const MoneyInput: FC<MoneyInputProps> = ({
  label,
  className,
  id,
  value,
  error,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const { active, focused, handleBlur, handleFocus } = useFormElementEvent(value, onFocus, onBlur);

  const handleKeyDown: KeyboardEventHandler = e => {
    if (['-', '+', 'e'].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <MoneyInputContainer
      active={active}
      focused={focused}
      error={!!error}
      disabled={!!inputProps.disabled}
      className={className}
    >
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        {...inputProps}
      />
      <Paragraph size="h3" weight="semiBold" color="tertiary" colorKey={500}>
        € <span>(TTC)</span>
      </Paragraph>
      {typeof error === 'string' && <MoneyInputError>{error}</MoneyInputError>}
    </MoneyInputContainer>
  );
};
