import {
  AvailableColors,
  AvailableColorsWithKey,
  Colors,
  DefaultTheme,
  PrimaryColor,
  SecondaryColor,
  StatusColor,
  TertiaryColor,
  ThemeProps,
} from 'styled-components';

import { rgba as polishedRgba } from 'polished';

function getColor<C extends Record<string, string>>(
  color: C,
  defaultKey: Extract<keyof C, number>,
  key?: Extract<keyof C, number>,
): string {
  return color[key ?? defaultKey];
}

export function primaryColor(key?: Extract<keyof PrimaryColor, number>): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.primary, 500, key);
}

export function secondaryColor(
  key?: Extract<keyof SecondaryColor, number>,
): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.secondary, 500, key);
}

export function tertiaryColor(key?: Extract<keyof TertiaryColor, number>): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.tertiary, 700, key);
}

export function blackColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.black;
}

export function whiteColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.white;
}

export function statusColor(key: Extract<keyof StatusColor, string>): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => theme.colors.status[key];
}

export function citymagColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.citymag;
}

export interface ColorProps<K extends AvailableColors> {
  color?: K;
  colorKey?: K extends AvailableColorsWithKey ? Extract<keyof Colors[K], number> : never;
}

function getAvailableColorsWithKeyValue<K extends AvailableColorsWithKey>(
  type: K,
  key?: Extract<keyof Colors[K], number>,
): (props: ThemeProps<DefaultTheme>) => string {
  return props => {
    switch (type) {
      case 'primary':
        return primaryColor(key as Extract<keyof PrimaryColor, number>)(props);
      case 'secondary':
        return secondaryColor(key as Extract<keyof SecondaryColor, number>)(props);
      case 'tertiary':
        return tertiaryColor(key as Extract<keyof TertiaryColor, number>)(props);
      default:
        return '';
    }
  };
}

export function getColorFromColorProps<K extends AvailableColors, P extends ColorProps<K>>(
  colorProps: P,
  defaultColor?: (props: ThemeProps<DefaultTheme>) => string,
): (props: ThemeProps<DefaultTheme>) => string | undefined {
  return props => {
    switch (colorProps.color) {
      case 'black':
        return blackColor(props);
      case 'white':
        return whiteColor(props);
      /*case 'citymag':
        return citymagColor(props);*/
      case 'primary':
      case 'secondary':
      case 'tertiary':
        return getAvailableColorsWithKeyValue(
          colorProps.color as AvailableColorsWithKey,
          colorProps.colorKey as Extract<keyof Colors[AvailableColorsWithKey], number>,
        )(props);
      default:
        return defaultColor ? defaultColor(props) : undefined;
    }
  };
}

export function rgba(
  colorFn: (props: ThemeProps<DefaultTheme>) => string,
  amount: number,
): (props: ThemeProps<DefaultTheme>) => string {
  return props => polishedRgba(colorFn(props), amount);
}
