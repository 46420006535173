import { useState, FocusEvent, useEffect } from 'react';

function isActive(value?: string | string[] | readonly string[] | number) {
  return (typeof value === 'string' && value.length > 0) || typeof value === 'number';
}

export const useFormElementEvent = <T extends HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
  value?: string | string[] | readonly string[] | number,
  onFocus?: (e: FocusEvent<T>) => void,
  onBlur?: (e: FocusEvent<T>) => void,
) => {
  const [active, setActive] = useState<boolean>(() => isActive(value));

  useEffect(() => {
    setActive(isActive(value));
  }, [value]);

  const [focused, setFocused] = useState<boolean>(false);

  const handleFocus = (e: FocusEvent<T>) => {
    setActive(true);
    setFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: FocusEvent<T>) => {
    setActive(e.target.value.length !== 0);
    setFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  return {
    active,
    focused,
    handleFocus,
    handleBlur,
  };
};
