import React, { FC, useMemo } from 'react';
import { HttpError, HttpStatusCode } from '@core/http';

import * as Styled from './ErrorPage.styles';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import notFoundImage from '@assets/images/error/404.svg';
import errorImage from '@assets/images/error/error.svg';
import { Paragraph, Title } from '@styles/shared';
import { LinkButton } from '@styles/shared';

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  const httpError = useMemo(
    () =>
      pipe(
        O.fromNullable(error),
        O.getOrElse(() => HttpError.notFound),
      ),
    [error],
  );

  return (
    <Styled.ErrorPageContainer>
      <div>
        <img alt="Erreur" src={HttpStatusCode.NOT_FOUND === httpError.status ? notFoundImage : errorImage} />

        <Title>
          {HttpStatusCode.NOT_FOUND === httpError.status
            ? 'La page que vous cherchez est introuvable'
            : `Une erreur ${httpError.status} est survenue`}
        </Title>

        <Paragraph>Nous vous invitons à revenir à l'accueil</Paragraph>

        <LinkButton to="/">Revenir à l'accueil</LinkButton>
      </div>
    </Styled.ErrorPageContainer>
  );
};

export default ErrorPage;
