import {
  DefaultTheme,
  FontSizes,
  FontWeights,
  PrimaryColor,
  SecondaryColor,
  StatusColor,
  TertiaryColor,
} from 'styled-components';

const primary: PrimaryColor = {
  200: '#e3f9f7',
  300: '#c9fbeb',
  400: '#00f4c2',
  500: '#06c9b2',
};

const secondary: SecondaryColor = {
  200: '#e2e4f2',
  300: '#9397a7',
  400: '#8185a0',
  500: '#292f4c',
};

const tertiary: TertiaryColor = {
  100: '#ffffff',
  200: '#f7f7f7',
  300: '#eaeaec',
  400: '#cacbce',
  500: '#ababb1',
  600: '#95969e',
  700: '#6b6d77',
  800: '#2c2e3d',
  900: '#1b1b24',
};

const status: StatusColor = {
  valid: '#1aae47',
  error: '#ff6969',
  pending: '#faa10e',
  preparation: '#2758f6',
};

const weights: FontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

const sizes: FontSizes = {
  h1: '28px',
  h2: '22px',
  h3: '17px',
  h4: '16px',
  h5: '14px',
  standard: '15px',
  medium: '14px',
  small: '13px',
  label: '12px',
  extraSmall: '11px',
};

const theme: DefaultTheme = {
  colors: {
    primary,
    secondary,
    tertiary,
    status,
    citymag: '#ff6d70',
    white: '#ffffff',
    black: '#000000',
  },
  font: {
    family: "'Montserrat', sans-serif",
    weights,
    sizes,
    style: {
      h1: {
        size: sizes.h1,
        weights: weights.regular,
        lineHeight: 1.36,
        fontStyle: 'normal',
        color: tertiary[800],
      },
      h1Inversed: {
        size: sizes.h1,
        weights: weights.regular,
        lineHeight: 1.36,
        fontStyle: 'normal',
        color: tertiary[100],
      },
      h2: {
        size: sizes.h2,
        weights: weights.semiBold,
        lineHeight: 1.09,
        fontStyle: 'normal',
        color: secondary[500],
      },
      h2Inversed: {
        size: sizes.h2,
        weights: weights.semiBold,
        lineHeight: 1.45,
        fontStyle: 'normal',
        color: tertiary[100],
      },
      h3: {
        size: sizes.h3,
        weights: weights.semiBold,
        lineHeight: 1.41,
        fontStyle: 'normal',
        color: secondary[500],
      },
      h4: {
        size: sizes.h4,
        weights: weights.regular,
        lineHeight: 1.5,
        fontStyle: 'normal',
        color: tertiary[800],
      },
      h5: {
        size: sizes.h5,
        weights: weights.semiBold,
        lineHeight: 1.71,
        fontStyle: 'normal',
        color: tertiary[900],
      },
      h5Inversed: {
        size: sizes.h5,
        weights: weights.semiBold,
        lineHeight: 1.14,
        fontStyle: 'normal',
        color: tertiary[100],
      },
      standard: {
        size: sizes.standard,
        weights: weights.regular,
        lineHeight: 1.6,
        fontStyle: 'normal',
        color: tertiary[800],
      },
      standardInversed: {
        size: sizes.standard,
        weights: weights.regular,
        lineHeight: 1.6,
        fontStyle: 'normal',
        color: tertiary[100],
      },
      placeholder: {
        size: sizes.medium,
        weights: weights.regular,
        lineHeight: 1.43,
        fontStyle: 'italic',
        color: tertiary[600],
      },
      medium: {
        size: sizes.medium,
        weights: weights.regular,
        lineHeight: 1.71,
        fontStyle: 'normal',
        color: tertiary[900],
      },
      mediumItalic: {
        size: sizes.medium,
        weights: weights.regular,
        lineHeight: 1.71,
        fontStyle: 'italic',
        color: tertiary[900],
      },
      small: {
        size: sizes.small,
        weights: weights.regular,
        lineHeight: 1.54,
        fontStyle: 'normal',
        color: tertiary[900],
      },
      smallBold: {
        size: sizes.small,
        weights: weights.semiBold,
        lineHeight: 1.54,
        fontStyle: 'normal',
        color: tertiary[900],
      },
      breadcrumb: {
        size: sizes.label,
        weights: weights.regular,
        lineHeight: 2,
        fontStyle: 'normal',
        color: secondary[400],
      },
      label: {
        size: sizes.extraSmall,
        weights: weights.bold,
        lineHeight: 1.82,
        fontStyle: 'normal',
        color: tertiary[500],
      },
      extraSmall: {
        size: sizes.extraSmall,
        weights: weights.regular,
        lineHeight: 1.55,
        fontStyle: 'normal',
        color: tertiary[700],
      },
      extraSmallItalic: {
        size: sizes.extraSmall,
        weights: weights.regular,
        lineHeight: 1.82,
        fontStyle: 'italic',
        color: tertiary[600],
      },
      extraSmallBold: {
        size: sizes.extraSmall,
        weights: weights.semiBold,
        lineHeight: 1.82,
        fontStyle: 'normal',
        color: tertiary[800],
      },
      status: {
        size: sizes.extraSmall,
        weights: weights.medium,
        lineHeight: 1.55,
        fontStyle: 'normal',
        color: tertiary[100],
      },
    },
  },
  breakpoints: {
    large: 1280,
    desktop: 960,
    tablet: 700,
    mobile: 560,
  },
};

export default theme;
