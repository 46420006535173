import styled from 'styled-components';
import { fontSize, fontWeight, mediaMax, tertiaryColor } from '@styles/utils';

export const VirtualizedTableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VirtualizedTableHeader = styled.div`
  display: grid;
  align-items: center;
  padding: 15px 25px 15px 20px;
  background: ${tertiaryColor(100)};
  border-bottom: 1px solid ${tertiaryColor(300)};
  font-size: ${fontSize.extraSmall};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(500)};
  text-transform: uppercase;

  @media screen and (${mediaMax.mobile}) {
    display: none;
  }
`;

export const VirtualizedTableRow = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  padding: 15px 25px 15px 20px;
`;
