import { useAuthContext } from '@modules/auth/context';
import { useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useEffect } from 'react';
import { ApplicationSpace, getApplicationSpace, getConfigValue } from '@shared/utils/config';
import * as O from 'fp-ts/Option';

export function useAnalyticsTracker() {
  const { descriptor, profile } = useAuthContext();

  const location = useLocation();

  const { trackPageView, pushInstruction } = useMatomo();

  useEffect(() => {
    trackPageView({});
  }, [location, trackPageView]);

  useEffect(() => {
    const release = getConfigValue('VITE_RELEASE');
    const applicationSpace = getApplicationSpace();

    if (O.isSome(descriptor)) {
      pushInstruction('setCustomDimension', 1, descriptor.value.code);
    }

    if (O.isSome(applicationSpace)) {
      pushInstruction('setCustomDimension', 2, applicationSpace.value);
    }

    if (O.isSome(profile) && ApplicationSpace.Pro === profile.value.space) {
      pushInstruction('setCustomDimension', 3, profile.value.type);
    }

    if (O.isSome(release)) {
      pushInstruction('setCustomDimension', 4, release.value);
    }

    pushInstruction('setCustomDimension', 5, O.isSome(profile));
  }, [descriptor, profile, pushInstruction]);
}
