import React from 'react';
import {
  adminPredicate,
  communityPredicate,
  NavItemType,
  NavList,
  proPredicate,
  shopPredicate,
} from '@shared/model/nav';
import {
  IconBusinessplan,
  IconDeviceDesktop,
  IconFlag,
  IconFloatLeft,
  IconHome,
  IconLock,
  IconPencil,
  IconPhoto,
  IconReportMoney,
  IconShoppingCart,
  IconUsers,
  IconWorld,
} from '@tabler/icons';
import { pipe } from 'fp-ts/function';
import * as P from 'fp-ts/Predicate';

export const navList = {
  dashboard: {
    type: NavItemType.Link,
    title: 'Tableau de bord',
    to: '/dashboard',
    icon: <IconHome />,
  },
  shopCommunication: {
    type: NavItemType.List,
    title: 'Communication',
    subList: [
      {
        label: 'Actualités',
        to: '/communication/public/news',
      },
      {
        label: 'Agenda',
        to: '/communication/public/calendar',
      },
      {
        label: 'Sondage',
        to: '/communication/public/survey',
      },
    ],
    icon: <IconWorld />,
    canAccess: shopPredicate,
  },
  privateCommunication: {
    type: NavItemType.List,
    title: 'Communication privée',
    subList: [
      {
        label: 'Actualités',
        to: '/communication/internal/news',
      },
      {
        label: 'Agenda',
        to: '/communication/internal/calendar',
      },
      {
        label: 'Sondage',
        to: '/communication/internal/survey',
      },
      {
        label: 'Groupes de diffusion',
        to: '/communication/internal/broadcast/groups',
        canAccess: adminPredicate,
      },
      {
        label: 'Interlocuteurs',
        to: '/communication/internal/representatives',
        canAccess: adminPredicate,
      },
    ],
    icon: <IconLock />,
    canAccess: pipe(adminPredicate, P.or(communityPredicate)),
  },
  publicCommunication: {
    type: NavItemType.List,
    title: 'Communication publique',
    subList: [
      {
        label: 'Actualités',
        to: '/communication/public/news',
      },
      {
        label: 'Agenda',
        to: '/communication/public/calendar',
      },
      {
        label: 'Sondage',
        to: '/communication/public/survey',
      },
      {
        label: 'Newsletter',
        to: '/communication/public/newsletter',
        canAccess: adminPredicate,
      },
      {
        label: "Communication d'urgence",
        to: '/communication/public/urgency',
        canAccess: adminPredicate,
      },
    ],
    icon: <IconWorld />,
    canAccess: pipe(adminPredicate, P.or(communityPredicate)),
  },
  partners: {
    type: NavItemType.List,
    title: 'Partenaires',
    subList: [
      {
        label: 'Professionnels',
        to: '/partners/shops',
      },
      {
        label: 'Associations',
        to: '/partners/communities',
      },
    ],
    icon: <IconUsers />,
    canAccess: adminPredicate,
  },
  members: {
    type: NavItemType.List,
    title: 'Gestion des membres',
    subList: [
      {
        label: 'Liste des membres',
        to: '/members',
        exact: true,
      },
      {
        label: 'Adhésions en ligne',
        to: '/members/adhesion-campaigns',
      },
      {
        label: 'Groupes de diffusion',
        to: '/members/broadcast/groups',
      },
    ],
    icon: <IconUsers />,
    canAccess: communityPredicate,
  },
  reporting: {
    type: NavItemType.List,
    title: 'Signalements',
    subList: [
      {
        label: 'Signalements reçus',
        to: '/my-city/reporting',
      },
      {
        label: 'Paramètres',
        to: '/parameters/reporting-categories',
      },
    ],
    icon: <IconFlag />,
    canAccess: adminPredicate,
  },
  offers: {
    type: NavItemType.List,
    title: 'E-boutique',
    subList: [
      {
        label: 'Offres',
        to: '/offers',
      },
      {
        label: 'Commandes',
        to: '/offer-orders',
      },
    ],
    icon: <IconShoppingCart />,
    canAccess: proPredicate,
  },
  payments: {
    type: NavItemType.List,
    title: 'Paiements',
    subList: [
      {
        label: 'Appels de règlements',
        to: '/payments/campaigns',
        canAccess: pipe(shopPredicate, P.or(communityPredicate)),
      },
      {
        label: 'Synthèse des règlements',
        to: '/payments/requests',
      },
      {
        label: 'Mouvements bancaires',
        to: '/payments/transactions',
      },
      {
        label: 'Configuration',
        to: '/payments/configure',
      },
    ],
    icon: <IconBusinessplan />,
  },
  website: {
    type: NavItemType.List,
    title: 'Site web',
    subList: [
      {
        label: "Page d'accueil",
        to: '/institutional-website/home',
      },
      {
        label: 'Menu et pages',
        to: '/institutional-website/pages',
      },
      {
        label: 'Thème',
        to: '/institutional-website/design',
      },
      {
        label: 'Paramètres',
        to: '/institutional-website/parameters',
      },
    ],
    icon: <IconDeviceDesktop />,
    canAccess: adminPredicate,
  },
  donations: {
    type: NavItemType.Link,
    title: 'Dons',
    to: '/donations',
    // FIXME change icon
    icon: <IconReportMoney />,
    canAccess: communityPredicate,
  },
  advertising: {
    type: NavItemType.Link,
    title: 'Publicité',
    to: '/advertising/campaigns',
    icon: <IconFloatLeft />,
    canAccess: proPredicate,
  },
  city: {
    type: NavItemType.List,
    title: 'Mairie',
    subList: [
      {
        label: 'Liens utiles',
        to: '/my-city/external-links',
      },
      {
        label: 'Services municipaux',
        to: '/my-city/contacts',
      },
      {
        label: 'Carte',
        to: '/my-city/poi',
      },
      {
        label: 'Catégories professionnelles',
        to: '/parameters/shops-categories',
      },
      {
        label: 'Thématiques actualités',
        to: '/parameters/topics',
      },
      {
        label: 'Réseaux sociaux',
        to: '/social-networks',
        isActive: (_, location) =>
          location.pathname === '/parameters/cross-post' || location.pathname === '/social-networks',
      },
      {
        label: 'Réglages des notifications',
        to: '/mail/forwards',
      },
      {
        label: 'Gestion des administrateurs',
        to: '/users',
      },
    ],
    icon: <IconPencil />,
    canAccess: adminPredicate,
  },
  community: {
    type: NavItemType.List,
    title: 'Mon association',
    subList: [
      {
        label: 'Ma page de présentation',
        to: '/presentation',
        exact: true,
      },
      {
        label: 'Réseaux sociaux',
        to: '/presentation/social-networks',
        isActive: (_, location) =>
          location.pathname === '/parameters/cross-post' || location.pathname === '/presentation/social-networks',
      },
      {
        label: 'Informations administratives',
        to: '/administrative',
      },
      {
        label: 'Gestion des administrateurs',
        to: '/users',
      },
      {
        label: 'Réglage des notifications',
        to: '/parameters/mail/forwards',
      },
      {
        label: 'Mes villes',
        to: '/my-cities',
      },
    ],
    icon: <IconPencil />,
    canAccess: communityPredicate,
  },
  shop: {
    type: NavItemType.List,
    title: 'Mon activité',
    subList: [
      {
        label: 'Ma page de présentation',
        to: '/presentation',
        exact: true,
      },
      {
        label: 'Horaires',
        to: '/presentation/opening-hours',
      },
      {
        label: 'Réseaux sociaux',
        to: '/presentation/social-networks',
        isActive: (_, location) =>
          location.pathname === '/parameters/cross-post' || location.pathname === '/presentation/social-networks',
      },
      {
        label: 'Informations administratives',
        to: '/administrative',
      },
      {
        label: 'Gestion des administrateurs',
        to: '/users',
      },
      {
        label: 'Mes villes',
        to: '/my-cities',
      },
    ],
    icon: <IconPencil />,
    canAccess: shopPredicate,
  },
  gallery: {
    type: NavItemType.Link,
    title: 'Galerie',
    to: '/gallery',
    icon: <IconPhoto />,
  },
} satisfies NavList;
