import React, { FC, Suspense } from 'react';

import { HelmetProvider } from 'react-helmet-async';

import theme from './styles/theme';

import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '@styles/global';
import { Router } from 'react-router-dom';
import { ApplicationSpace, getApplicationSpace } from '@shared/utils/config';
import { renderOptional } from '@shared/utils/render';
import { AuthContextProvider, useAuthContext } from '@modules/auth/context';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { safeLazy } from '@core/router';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import history from './app-history';
import { ImgixProvider } from 'react-imgix';
import AnalyticsProvider from '@shared/modules/analytics/components/AnalyticsProvider';
import { useAnalyticsTracker } from '@shared/modules/analytics/hooks';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';

const applicationSpace = getApplicationSpace();

const BackRoutes = safeLazy(() => import('./modules/back/routes'));
const ProRoutes = safeLazy(() => import('./modules/pro/routes'));

const routes: Record<ApplicationSpace, JSX.Element> = {
  [ApplicationSpace.Admin]: <BackRoutes />,
  [ApplicationSpace.Pro]: <ProRoutes />,
};

const AppContent: FC<{ space: ApplicationSpace }> = ({ space }) => {
  const { profile, descriptor } = useAuthContext();

  useAnalyticsTracker();

  return (
    <SentryProvider space={space} profile={profile} descriptor={descriptor}>
      <Suspense fallback={<DebouncedLineLoader />}>{routes[space]}</Suspense>
    </SentryProvider>
  );
};

const App: FC = () => (
  <HelmetProvider>
    <AnalyticsProvider>
      <ImgixProvider imgixParams={{ auto: 'compress,format' }} disableLibraryParam>
        <ThemeProvider theme={theme}>
          <GlobalStyles />

          <ApiDownIndicator />

          <Router history={history}>
            {renderOptional(
              applicationSpace,
              space => (
                <AuthContextProvider space={space}>
                  <AppContent space={space} />
                </AuthContextProvider>
              ),
              () => (
                <ErrorPage />
              ),
            )}
          </Router>
        </ThemeProvider>
      </ImgixProvider>
    </AnalyticsProvider>
  </HelmetProvider>
);

export default App;
