import styled from 'styled-components';
import { blackColor, fontSize, fontWeight, mediaMax, primaryColor, secondaryColor } from '@styles/utils';

export const TabsContainer = styled.ul`
  display: flex;
  min-width: 0;

  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  > li {
    &:not(:last-child) {
      margin-right: 25px;
    }

    > a {
      position: relative;
      display: block;
      padding-bottom: 8px;
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.semiBold};
      color: ${blackColor};
      text-decoration: none;

      &.active {
        color: ${primaryColor()};

        &:after {
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: ${primaryColor()};
        }
      }
    }
  }

  @media screen and (${mediaMax.tablet}) {
    padding: 8px 20px 0 20px;
    border-top: 1px solid ${secondaryColor(200)};
    width: 100vw;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
