import { ApplicationSpace } from '@shared/utils/config';
import { httpService, HttpTask } from '@core/http';
import { Descriptor } from './model';

const descriptorRoutes: Record<ApplicationSpace, string> = {
  [ApplicationSpace.Admin]: '/back/descriptor',
  [ApplicationSpace.Pro]: '/pro/descriptor',
};

export function getDescriptor(space: ApplicationSpace): HttpTask<Descriptor> {
  return httpService.get(descriptorRoutes[space]);
}
