import React, { FC, PropsWithChildren, useEffect } from 'react';
import { AuthContextProfile } from '@modules/auth/context';
import { Descriptor } from '@modules/descriptor/model';

import * as Sentry from '@sentry/react';

import * as O from 'fp-ts/Option';
import { ApplicationSpace } from '@shared/utils/config';
import { pipe } from 'fp-ts/function';
import ErrorPage from '../../../components/error-page/ErrorPage';
import { HttpError } from '@core/http';

function getSentryUser(profile: O.Option<AuthContextProfile>): Sentry.User {
  return pipe(
    profile,
    O.fold<AuthContextProfile, Sentry.User>(
      () => ({ username: 'Not authenticated' }),
      p => ({
        email: p.email,
        username: `${p.firstName} ${p.lastName}`,
        ...(p.space === ApplicationSpace.Pro
          ? {
              type: p.type,
              id: p.id,
            }
          : { type: 'admin' }),
      }),
    ),
  );
}

interface SentryProviderProps {
  space: ApplicationSpace;
  profile: O.Option<AuthContextProfile>;
  descriptor: O.Option<Descriptor>;
}

const SentryProvider: FC<PropsWithChildren<SentryProviderProps>> = ({ space, profile, descriptor, children }) => {
  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setUser(getSentryUser(profile));
    });
  }, [profile]);

  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setContext('app', {
        space,
        city: pipe(
          descriptor,
          O.map(descriptor => descriptor.code),
          O.toUndefined,
        ),
      });
    });
  }, [space, descriptor]);

  return <Sentry.ErrorBoundary fallback={<ErrorPage error={HttpError.default} />}>{children}</Sentry.ErrorBoundary>;
};

export default SentryProvider;
