import { ReactNode } from 'react';
import { AuthContextProfile } from '@modules/auth/context';
import { Predicate } from 'fp-ts/Predicate';
import { ApplicationSpace } from '@shared/utils/config';
import { NavLinkProps } from 'react-router-dom';

export enum NavItemType {
  Link = 'link',
  List = 'list',
}

export interface NavItemLink {
  type: NavItemType.Link;
  title: string;
  to: string;
  icon: ReactNode;
  canAccess?: Predicate<AuthContextProfile>;
}

export interface NavSubList {
  label: string;
  to: string;
  canAccess?: Predicate<AuthContextProfile>;
  exact?: boolean;
  isActive?: NavLinkProps['isActive'];
}

export interface NavItemList {
  type: NavItemType.List;
  title: string;
  subList: Array<NavSubList>;
  icon: ReactNode;
  canAccess?: Predicate<AuthContextProfile>;
}

export type NavItem = NavItemList | NavItemLink;

export enum NavListPage {
  Dashboard = 'dashboard',
  ShopCommunication = 'shopCommunication',
  PrivateCommunication = 'privateCommunication',
  PublicCommunication = 'publicCommunication',
  Partners = 'partners',
  Members = 'members',
  Reporting = 'reporting',
  Offers = 'offers',
  Payments = 'payments',
  Website = 'website',
  Donations = 'donations',
  Advertising = 'advertising',
  City = 'city',
  Community = 'community',
  Shop = 'shop',
  Gallery = 'gallery',
}

export type NavList = Record<NavListPage, NavItem>;

export const adminPredicate: Predicate<AuthContextProfile> = profile => profile.space === ApplicationSpace.Admin;

export const proPredicate: Predicate<AuthContextProfile> = profile => profile.space === ApplicationSpace.Pro;
export const shopPredicate: Predicate<AuthContextProfile> = profile =>
  profile.space === ApplicationSpace.Pro && profile.type === 'shop';
export const communityPredicate: Predicate<AuthContextProfile> = profile =>
  profile.space === ApplicationSpace.Pro && profile.type === 'community';
