import React, { FC, PropsWithChildren } from 'react';
import { pipe } from 'fp-ts/function';
import { sequenceT } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { getConfigValue } from '@shared/utils/config';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { renderOptional } from '@shared/utils/render';

const matomoSiteId = pipe(
  getConfigValue('VITE_MATOMO_SITE_ID'),
  O.map(id => parseInt(id.trim(), 10)),
  O.filter(id => !isNaN(id)),
);

const matomoInstance = pipe(
  sequenceT(O.Apply)(getConfigValue('VITE_MATOMO_DOMAIN'), matomoSiteId),
  O.map(([domain, siteId]) =>
    createInstance({
      urlBase: `https://${domain}`,
      srcUrl: `https://cdn.matomo.cloud/${domain}/matomo.js`,
      siteId,
    }),
  ),
);

const AnalyticsProvider: FC<PropsWithChildren<unknown>> = ({ children }) =>
  renderOptional(
    matomoInstance,
    instance => (
      <>
        {/* @ts-expect-error - Temporary React 18 types fix */}
        <MatomoProvider value={instance}>{children}</MatomoProvider>
      </>
    ),
    () => <>{children}</>,
  );

export default AnalyticsProvider;
