import React from 'react';

import styled, {
  AvailableColors,
  css,
  DefaultTheme,
  FontSizeKey,
  FontWeightKey,
  StyledComponentProps,
} from 'styled-components';
import { blackColor, ColorProps, fontSize, fontWeight, getColorFromColorProps } from '../utils';
import { shouldForwardPropHelper } from '../utils/props';

export interface ParagraphProps<K extends AvailableColors> extends ColorProps<K> {
  size?: FontSizeKey;
  weight?: FontWeightKey;
  uppercase?: boolean;
  margin?: string;
}

function paragraphStyle<K extends AvailableColors>(props: ParagraphProps<K>) {
  return css`
    color: ${getColorFromColorProps(props, blackColor)};
    font-size: ${fontSize[props.size ?? 'standard']};
    font-weight: ${fontWeight[props.weight ?? 'regular']};
    text-transform: ${props.uppercase ? 'uppercase' : 'none'};
    margin: ${props.margin};
  `;
}

export function getParagraphStyle<K extends AvailableColors>(props: ParagraphProps<K>) {
  return paragraphStyle(props);
}

const StyledParagraph = styled.p.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => shouldForwardPropHelper(prop, defaultValidatorFn, ['color']),
})<ParagraphProps<any>>`
  ${props => paragraphStyle(props as any)};
`;

export function Paragraph<K extends AvailableColors, C extends string | React.ComponentType<any>, A extends keyof any>(
  props: ParagraphProps<K> & StyledComponentProps<C, DefaultTheme, ParagraphProps<K>, A> & { as?: C; forwardedAs?: C },
) {
  return <StyledParagraph {...(props as any)} />;
}
