import styled, { css } from 'styled-components';

import { fontStyle, mediaHeightMax, mediaMax, secondaryColor, tertiaryColor } from '@styles/utils';
import { Title } from '@styles/shared';
import { Link } from 'react-router-dom';
import React from 'react';

export const PageContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;
`;

export const PageTop = styled.div`
  position: relative;
  z-index: 1000;
  padding: 0 20px;
  background: ${tertiaryColor(100)};
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.04);
  min-width: 0;

  @media screen and (${mediaMax.tablet}) {
    padding: 0 20px 0 0;
    overflow-x: hidden;
  }
`;

export const PageTopContent = styled.div`
  padding: 0 70px;
  margin: 0 auto;

  @media screen and (${mediaMax.tablet}) {
    padding: 0;
  }
`;

export const PageTopTitleContainer = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 87px;

  @media screen and (${mediaMax.tablet}) {
    padding-left: 80px;
  }
`;

export const PageTopTitleBreadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (${mediaMax.tablet}) {
    padding-right: 10px;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    width: calc(100vw - 80px);

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const PageTopTitlePage = styled.div`
  display: grid;
  grid-template-columns: 15px 1fr;
  grid-column-gap: 3px;
  align-items: center;
  white-space: nowrap;

  > svg {
    width: 15px;
    height: 15px;
    color: ${secondaryColor(400)};
  }

  @media screen and (${mediaMax.tablet}) {
    display: flex;

    > svg {
      display: none;
    }
  }
`;

export const PageTopTitlePageLabel = styled.div`
  ${fontStyle.breadcrumb};
  text-transform: uppercase;
`;

export const PageTopTitleBreadcrumbList = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  list-style: disc inside;
`;

export const PageTopTitleBreadcrumbItem = styled.div`
  position: relative;
  margin-left: 5px;
  padding-left: 8px;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 3px;
    border-radius: 20px;
    background-color: ${secondaryColor(400)};
  }
`;

export const PageTopTitleBreadcrumbItemLink = styled(props => <Link {...props} />)`
  ${fontStyle.breadcrumb};
`;

export const PageTopTitle = styled(props => <Title {...props} />)`
  ${fontStyle.h2};
`;

export const PageContentWrapper = styled.div<{ full?: boolean }>`
  padding: 25px 0;
  overflow-x: hidden;
  overflow-y: auto;

  ${mediaHeightMax`
    padding: 10px 0;
  `}

  ${props =>
    props.full &&
    css`
      padding: 0 !important;
    `}
`;

export const PageContent = styled.div<{ full?: boolean }>`
  display: grid;
  min-width: 0;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1000px;

  @media screen and (${mediaMax.tablet}) {
    padding: 0 15px;
  }

  ${props =>
    props.full &&
    css`
      padding: 0 !important;
      margin: 0;
      max-width: none;
    `}
`;

export const PageBottom = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 30px;
  background: ${tertiaryColor(100)};
  border-top: 1px solid ${tertiaryColor(100)};

  ${mediaHeightMax`
    padding: 5px 30px;
  `}

  @media screen and (${mediaMax.mobile}) {
    padding: 8px 20px;
  }
`;
