import styled, { keyframes } from 'styled-components';
import { primaryColor } from '@styles/utils';
import { shouldForwardPropHelper } from '@styles/utils/props';

interface RoundLoaderProps {
  width: number;
}

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RoundLoader = styled.div
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => shouldForwardPropHelper(prop, defaultValidatorFn, ['width']),
  })
  .attrs<Partial<RoundLoaderProps>, RoundLoaderProps>(props => ({
    width: props.width ?? 20,
  }))`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.width}px;

  &:before {
    border-radius: 50%;
    animation: ${spinnerAnimation} 1.2s linear infinite;
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: calc(50% - (21px / 2));
    left: calc(50% - (19px / 2));
    border: 2px solid ${primaryColor()};
    border-top-color: transparent;
  }
`;

export default RoundLoader;
