import React from 'react';
import styled, {
  AvailableColors,
  css,
  DefaultTheme,
  FlattenInterpolation,
  FontSizeKey,
  FontWeightKey,
  StyledComponentProps,
  ThemeProps,
} from 'styled-components';

import { ColorProps, fontSize, fontWeight, getColorFromColorProps } from '../utils';
import { shouldForwardPropHelper } from '../utils/props';

export type TitleLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export const titleStyles: Record<TitleLevel, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  h1: css`
    font-size: ${fontSize.h1};
    line-height: 1.33;
  `,
  h2: css`
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.semiBold};
    line-height: 1.45;
  `,
  h3: css`
    font-size: ${fontSize.h3};
    font-weight: ${fontWeight.semiBold};
    line-height: 1.41;
  `,
  h4: css`
    font-size: ${fontSize.h4};
    line-height: 1.41;
  `,
  h5: css`
    font-size: ${fontSize.medium};
    font-weight: ${fontWeight.semiBold};
    line-height: 1.5;
  `,
};

interface TitleProps<K extends AvailableColors> extends ColorProps<K> {
  level?: TitleLevel;
  size?: FontSizeKey;
  weight?: FontWeightKey;
  margin?: string;
}

const StyledTitle = styled.h1.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => shouldForwardPropHelper(prop, defaultValidatorFn, ['color']),
})<TitleProps<any>>`
  ${props => titleStyles[props.level ?? 'h1']};

  color: ${props => getColorFromColorProps(props as any)};
  margin: ${props => props.margin};

  ${props =>
    props.size &&
    css`
      font-size: ${fontSize[props.size]};
    `}

  ${props =>
    props.weight &&
    css`
      font-weight: ${fontWeight[props.weight]};
    `}
`;

export function Title<K extends AvailableColors, C extends string | React.ComponentType<any>, A extends keyof any>(
  props: TitleProps<K> & StyledComponentProps<C, DefaultTheme, TitleProps<K>, A> & { as?: C; forwardedAs?: C },
) {
  return <StyledTitle {...(props as any)} />;
}
