import styled, { css, keyframes } from 'styled-components';
import { fontSize, mediaHeightMax, rgba, tertiaryColor } from '@styles/utils';

export const VirtualizedTableContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
`;

export const VirtualizedTableContent = styled.div`
  position: relative;
  width: 100%;
`;

const loadingPlaceholder = keyframes`
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
`;

export const VirtualizedTableRowWrapper = styled.div<{ $loading?: boolean; $hover?: boolean }>`
  background: ${tertiaryColor(100)};
  font-size: ${fontSize.medium};
  color: ${tertiaryColor(900)};
  text-decoration: none;

  &:not(:first-child) {
    border-top: 1px solid ${tertiaryColor(300)};
  }

  ${props =>
    props.$loading &&
    css`
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background-image: linear-gradient(
          to right,
          ${rgba(tertiaryColor(100), 0.1)} 5%,
          ${rgba(tertiaryColor(100), 0.5)} 15%,
          ${rgba(tertiaryColor(100), 0.3)} 70%,
          ${rgba(tertiaryColor(100), 0.2)} 10%
        );
        background-size: 200%;
        transform: translateX(0);
        animation: 2s ${loadingPlaceholder} ease-in-out infinite;
      }
    `}

  ${props =>
    props.$hover &&
    css`
      transition: background 0.15s linear;

      &:hover {
        background: ${rgba(tertiaryColor(100), 0.3)};
        cursor: pointer;
      }
    `}
`;

export const VirtualizedTableNoRow = styled(VirtualizedTableRowWrapper)`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  will-change: unset;
  border-top: none !important;

  ${mediaHeightMax`
    height: 45px;
  `}
`;
