import React, { FC, useLayoutEffect, useState } from 'react';
import { constVoid } from 'fp-ts/function';
import Modal from '@shared/components/modal/Modal';
import { Paragraph } from '@styles/shared';
import RoundLoader from '@layout/loaders/round-loader/RoundLoader';

const API_DOWN_EVENT_NAME = 'api-down';
const API_UP_EVENT_NAME = 'api-up';

const ApiDownIndicator: FC = () => {
  const [opened, setOpened] = useState<boolean>(false);

  useLayoutEffect(() => {
    const showIndicator = () => setOpened(true);
    const hideIndicator = () => setOpened(false);

    window.addEventListener(API_DOWN_EVENT_NAME, showIndicator);
    window.addEventListener(API_UP_EVENT_NAME, hideIndicator);

    return () => {
      window.removeEventListener(API_DOWN_EVENT_NAME, showIndicator);
      window.removeEventListener(API_UP_EVENT_NAME, hideIndicator);
    };
  }, []);

  return (
    <Modal title="Connexion avec le serveur interrompue" isOpen={opened} onRequestClose={constVoid}>
      <div
        style={{
          paddingBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <RoundLoader />
      </div>

      <Paragraph>Veuillez patienter nous essayons de rétablir la connexion</Paragraph>
    </Modal>
  );
};

export function showApiDownIndicator() {
  window.dispatchEvent(new Event(API_DOWN_EVENT_NAME));
}

export function hideApiDownIndicator() {
  window.dispatchEvent(new Event(API_UP_EVENT_NAME));
}

export default ApiDownIndicator;
