import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';

import { v4 as uuid } from 'uuid';
import { TRANSACTION_ID_HEADER_KEY } from './model';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { getConfigValue } from '@shared/utils/config';
import { stringifyQueries } from '@shared/utils/queries';

function getCityHeader(): Record<string, string> {
  const cityHeader = pipe(
    getConfigValue('VITE_CITY'),
    O.fold(
      () => ({}),
      city => ({ 'X-CityMag-City': city }),
    ),
  );

  return {
    ...cityHeader,
    'X-CityMag-Host': window.location.hostname,
  };
}

export const defaultAxiosInstance = axios.create({
  paramsSerializer: {
    serialize: stringifyQueries,
  },
  withCredentials: true,
});

function injectHeaders(headers: AxiosRequestConfig['headers']): AxiosHeaders {
  return AxiosHeaders.from(headers as any)
    .set(TRANSACTION_ID_HEADER_KEY, uuid())
    .set(getCityHeader());
}

defaultAxiosInstance.interceptors.request.use(config => ({
  ...config,
  headers: injectHeaders(config.headers),
}));
