import { httpService, HttpTask } from '@core/http';
import { BackProfile } from './model';
import { AuthenticateParams, ImpersonationToken } from '../../auth/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

export function getProfile(): HttpTask<BackProfile> {
  return httpService.get('/back/profile');
}

export function authenticate(params: AuthenticateParams): HttpTask<BackProfile> {
  return pipe(httpService.post(`/back/authenticate`, params), TE.chain(getProfile));
}

export function authenticateWithToken(token: ImpersonationToken): HttpTask<BackProfile> {
  return pipe(httpService.post('/back/authenticate', undefined, { params: { token } }), TE.chain(getProfile));
}

export function logout(): HttpTask<void> {
  return httpService.post('/back/logout');
}
