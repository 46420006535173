import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { BackProfile } from '../back/auth/model';
import { ApplicationSpace } from '@shared/utils/config';
import { CommunityProfile, ProProfile, ShopProfile } from '../pro/auth/model';
import { constVoid, Lazy, pipe } from 'fp-ts/function';
import { HttpTask } from '@core/http';

import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as TO from 'fp-ts/TaskOption';

import * as BackAuthService from '@modules/back/auth/service';
import * as ProAuthService from '@modules/pro/auth/service';
import * as DescriptorService from '@modules/descriptor/service';
import { getDescriptor } from '@modules/descriptor/service';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import { isCommunityProfile, isShopProfile } from '../pro/auth/utils';
import { Refinement } from 'fp-ts/Refinement';
import { Descriptor } from '@modules/descriptor/model';
import { sequenceT } from 'fp-ts/Apply';

const AUTH_PROFILE_SYNC_KEY = 'CM_PROFILE_SYNC';

function requestProfileSync() {
  localStorage.setItem(AUTH_PROFILE_SYNC_KEY, Date.now().toString());
}

export type AuthContextBackProfile = BackProfile & {
  space: ApplicationSpace.Admin;
};

export type AuthContextProProfile = ProProfile & {
  space: ApplicationSpace.Pro;
};

export type AuthContextProfile = AuthContextBackProfile | AuthContextProProfile;

export function isAuthContextBackProfile(profile: AuthContextProfile): profile is AuthContextBackProfile {
  return ApplicationSpace.Admin === profile.space;
}

export function isAuthContextProProfile(profile: AuthContextProfile): profile is AuthContextProProfile {
  return ApplicationSpace.Pro === profile.space;
}

export function mapBackProfileToContext(profile: BackProfile): AuthContextProfile {
  return {
    space: ApplicationSpace.Admin,
    ...profile,
  };
}

export function mapProProfileToContext(profile: ProProfile): AuthContextProfile {
  return {
    space: ApplicationSpace.Pro,
    ...profile,
  };
}

function getProfileHandler<P>(
  profileTask: HttpTask<P>,
  descriptorTask: HttpTask<Descriptor>,
  profileMapper: (f: P) => AuthContextProfile,
): T.Task<[O.Option<AuthContextProfile>, O.Option<Descriptor>]> {
  return sequenceT(T.ApplyPar)(
    pipe(TO.fromTaskEither(profileTask), TO.map(profileMapper)),
    TO.fromTaskEither(descriptorTask),
  );
}

export const getProfileTasks: Record<ApplicationSpace, T.Task<[O.Option<AuthContextProfile>, O.Option<Descriptor>]>> = {
  [ApplicationSpace.Admin]: getProfileHandler(
    BackAuthService.getProfile(),
    DescriptorService.getDescriptor(ApplicationSpace.Admin),
    mapBackProfileToContext,
  ),
  [ApplicationSpace.Pro]: getProfileHandler(
    ProAuthService.getProfile(),
    DescriptorService.getDescriptor(ApplicationSpace.Pro),
    mapProProfileToContext,
  ),
};

const logoutTasks: Record<ApplicationSpace, Lazy<HttpTask<void>>> = {
  [ApplicationSpace.Admin]: BackAuthService.logout,
  [ApplicationSpace.Pro]: ProAuthService.logout,
};

export interface AuthContextValue {
  profile: O.Option<AuthContextProfile>;
  descriptor: O.Option<Descriptor>;
  updateProfile: (profile: AuthContextProfile) => void;
  updateDescriptor: (descriptor: Descriptor) => void;
  requestUpdateProfile: Lazy<void>;
  requestUpdateDescriptor: Lazy<void>;
  logout: Lazy<void>;
}

export const AuthContext = createContext<AuthContextValue>({
  profile: O.none,
  descriptor: O.none,
  updateProfile: constVoid,
  updateDescriptor: constVoid,
  requestUpdateProfile: constVoid,
  requestUpdateDescriptor: constVoid,
  logout: constVoid,
});

interface AuthContextProviderProps {
  space: ApplicationSpace;
}

export const AuthContextProvider: FC<PropsWithChildren<AuthContextProviderProps>> = ({ space, children }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [profile, setProfile] = useState<O.Option<AuthContextProfile>>(O.none);
  const [descriptor, setDescriptor] = useState<O.Option<Descriptor>>(O.none);

  const fetchProfile = useCallback(() => {
    pipe(
      getProfileTasks[space],
      T.chainIOK(([profile, descriptor]) => () => {
        setProfile(profile);
        setDescriptor(descriptor);
        setLoading(false);
      }),
    )();
  }, [space]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  // Window / tabs sync handler
  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === AUTH_PROFILE_SYNC_KEY) {
        fetchProfile();
      }
    };

    window.addEventListener('storage', syncLogout, { passive: true });

    return () => {
      window.removeEventListener('storage', syncLogout);
      localStorage.removeItem(AUTH_PROFILE_SYNC_KEY);
    };
  }, [fetchProfile]);

  const requestUpdateDescriptor = useCallback(() => {
    pipe(
      getDescriptor(space),
      T.chainIOK(descriptor => () => setDescriptor(O.fromEither(descriptor))),
    )();
  }, [space]);

  const updateProfile = useCallback(
    (profile: AuthContextProfile) => {
      setProfile(O.some(profile));
      requestProfileSync();
      requestUpdateDescriptor();
    },
    [requestUpdateDescriptor],
  );

  const updateDescriptor = useCallback((descriptor: Descriptor) => setDescriptor(O.some(descriptor)), []);

  const requestUpdateProfile = useCallback(() => {
    fetchProfile();
    requestUpdateDescriptor();
    requestProfileSync();
  }, [fetchProfile, requestUpdateDescriptor]);

  const logout = useCallback(() => {
    pipe(
      logoutTasks[space](),
      T.chainIOK(() => () => {
        setProfile(O.none);
        requestProfileSync();
      }),
    )();
  }, [space]);

  const ctx = useMemo<AuthContextValue>(
    () => ({
      profile,
      descriptor,
      updateProfile,
      updateDescriptor,
      requestUpdateDescriptor,
      requestUpdateProfile,
      logout,
    }),
    [profile, descriptor, updateProfile, updateDescriptor, requestUpdateDescriptor, requestUpdateProfile, logout],
  );

  return <AuthContext.Provider value={ctx}>{loading ? <DebouncedLineLoader /> : children}</AuthContext.Provider>;
};

export function useAuthContext(): AuthContextValue {
  return useContext(AuthContext);
}

export interface UseSpecificAuthContextValue<T> {
  profile: O.Option<T>;
  updateProfile: (profile: T) => void;
  requestUpdateProfile: () => void;
  logout: () => void;
}

function useSpecificAuthContext<S extends AuthContextProfile, R extends Omit<S, 'space'>>(
  refinement: Refinement<AuthContextProfile, S>,
  mapper: (value: R) => AuthContextProfile,
): UseSpecificAuthContextValue<R> {
  const { profile, updateProfile, requestUpdateProfile, logout } = useAuthContext();

  const filteredProfile = useMemo(
    () =>
      pipe(
        profile,
        O.filter(refinement),
        O.map(({ space, ...rest }) => rest as R),
      ),
    [profile, refinement],
  );

  const handleUpdateProfile = useCallback((p: R) => updateProfile(mapper(p)), [updateProfile, mapper]);

  return {
    profile: filteredProfile,
    updateProfile: handleUpdateProfile,
    requestUpdateProfile,
    logout,
  };
}

export function useBackAuthContext(): UseSpecificAuthContextValue<BackProfile> {
  return useSpecificAuthContext(isAuthContextBackProfile, mapBackProfileToContext);
}

export function useProAuthContext(): UseSpecificAuthContextValue<ProProfile> {
  return useSpecificAuthContext(isAuthContextProProfile, mapProProfileToContext);
}

function useProSpecificAuthContext<T extends ProProfile>(
  refinement: Refinement<ProProfile, T>,
): UseSpecificAuthContextValue<T> {
  const { profile, updateProfile, requestUpdateProfile, logout } = useProAuthContext();

  const filteredProfile = useMemo(() => pipe(profile, O.filter(refinement)), [profile, refinement]);

  return {
    profile: filteredProfile,
    updateProfile,
    requestUpdateProfile,
    logout,
  };
}

export function useProCommunityAuthContext(): UseSpecificAuthContextValue<CommunityProfile> {
  return useProSpecificAuthContext(isCommunityProfile);
}

export function useProShopAuthContext(): UseSpecificAuthContextValue<ShopProfile> {
  return useProSpecificAuthContext(isShopProfile);
}
